<template>
	<div>
		<Form style="background: #fff; padding: 16px;" :label-width="100">
			<FormItem label="套餐名称" style="width: 420px;">
				<Input v-model="search.name" placeholder="输入套餐名称搜索"></Input>
			</FormItem>
			<FormItem label="上架状态" style="width: 420px;">
				<Select v-model="search.status">
					<Option :value="-1">全部</Option>
					<Option :value="0">已下架</Option>
					<Option :value="1">已上架</Option>
				</Select>
			</FormItem>
			<FormItem>
				<Button @click="openModal()" style="margin-right: 12px;">添加套餐</Button>
				<Button @click="init()" type="primary">搜索</Button>
			</FormItem>
		</Form>
		<Table :columns="columns" :data="data" :loading="loading">
			<template #status="{row}">
				<Tag :color="row.status === 1 ? 'success' : 'default'">{{ row.status === 1 ? '已上架' : '已下架' }}</Tag>
			</template>
			<template #operate="{row}">
				<Button @click="openModal(row)" style="margin-right: 12px;">编辑</Button>
				<Button @click="statusChange(row.status,row.id)">{{ row.status === 1 ? '下架' : '上架' }}</Button>
			</template>
		</Table>
		<div style="display: flex; justify-content: flex-end; background: #fff; padding: 16px;">
			<Page :total="search.total" :current="search.page" :page-size="search.pageSize" :page-size-opts="[15,30,50]"
				show-total show-sizer @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
		</div>
		<Modal title="添加套餐" v-model="modal.show" @on-cancel="colseModal()">
			<Form :model="modal.data" :label-width="120" label-colon>
				<FormItem label="套餐名称">
					<Input v-model="modal.data.name"></Input>
				</FormItem>
				<FormItem label="套餐价格">
					<Input v-model="modal.data.price" type="number"></Input>
				</FormItem>
				<FormItem label="套餐包含次数">
					<Input v-model="modal.data.amount" type="number"></Input>
				</FormItem>
			</Form>
			<template #footer>
				<Button @click="colseModal()">取消</Button>
				<Button type="primary" @click="confirmModal()">确认</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	var modalData = {
		name: '',
		price: 0.00,
		amount: 0
	}
	export default {
		data() {
			return {
				search: {
					total: 0,
					pageSize: 15,
					page: 1,
					name: '',
					status: -1
				},
				loading: false,
				columns: [{
						title: '套餐名称',
						key: 'name'
					},
					{
						title: '套餐价格',
						key: 'price'
					},
					{
						title: '套餐包含次数',
						key: 'amount'
					},
					{
						title: '上架状态',
						slot: 'status'
					},
					{
						title: '操作',
						slot: 'operate'
					}
				],
				data: [],
				modal: {
					show: false,
					data: {
						name: '',
						price: 0.00,
						amount: 0
					}
				}
			}
		},
		created() {
			this.init();
		},
		methods: {
			// 初始化数据
			init() {
				const _this = this;
				this.loading = true;
				this.requestApi('/adm/get_ai_package_list', {
					search: this.search
				}).then((res) => {
					if (res.status === 1) {
						_this.loading = false;
						_this.data = res.data.data;
						_this.search.total = res.data.total;
					}
				});
			},
			// 页码 改变事件
			pageChange(data) {
				this.search.page = data;
				this.init();
			},
			// 每页条数 改变事件
			pageSizeChange(data) {
				this.search.pageSize = data;
				this.init();
			},
			// 新增/编辑 套餐 弹窗
			openModal(data = null) {
				const _this = this;
				// 编辑 显示当前信息
				if (data) {
					_this.modal.data.id = data.id;
					for (let index in modalData) {
						_this.modal.data[index] = data[index];
					}
				}
				// 打开弹窗
				this.modal.show = true;
			},
			// 取消 新增/编辑 套餐
			colseModal() {
				this.modal.data = JSON.parse(JSON.stringify(modalData));
				this.modal.show = false;
			},
			// 确认 新增/编辑 套餐
			confirmModal() {
				const _this = this;
				this.requestApi('/adm/save_ai_package', {
					data: this.modal.data
				}).then((res) => {
					if (res.status === 1) {
						_this.alertSucc('保存成功');
						_this.colseModal();
						_this.init();
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			// 上架/下架 套餐
			statusChange(status, id) {
				const _this = this;
				this.requestApi('/adm/change_status_ai_package', {
					status,
					id
				}).then((res) => {
					if (res.status === 1) {
						_this.alertSucc(status === 1 ? '下架成功' : '上架成功');
						_this.init();
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>